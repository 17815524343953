import { Rive } from '@rive-app/canvas';

document.addEventListener('DOMContentLoaded', () => {
  const riveCanvasLarge = document.getElementById('rive-canvas');
  const riveCanvasSmall = document.getElementById('rive-canvas-small');
  const variation = $.cookie('_sofatutor_conv_100252290');

  if (!riveCanvasLarge && !riveCanvasSmall) return;
  if (!variation) return;

  const riveSrc = '/assets/application/orderflow/welcome_racoon.riv';

  const riveInstanceLarge = new Rive({
    src: riveSrc,
    canvas: riveCanvasLarge,
    autoplay: true,
    onLoad: () => {
      riveInstanceLarge.resizeDrawingSurfaceToCanvas();
    },
    onError: error => {
      console.error('Rive loading error:', error);
    }
  });

  if (riveCanvasSmall) {
    const riveInstanceSmall = new Rive({
      src: riveSrc,
      canvas: riveCanvasSmall,
      autoplay: true,
      onLoad: () => {
        riveInstanceSmall.resizeDrawingSurfaceToCanvas();
      }
    });
  }
});
